import './Shell.less';
import { trackClick } from './tracker';
import MonacoEditor, { monaco } from 'react-monaco-editor';
import {
  Badge,
  Button,
  Divider,
  Drawer,
  Input,
  Modal,
  Popover,
  Space,
  Switch,
  message,
} from 'antd';
import {
  CaretRightOutlined,
  ClearOutlined,
  DownSquareOutlined,
  EditOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  ImportOutlined,
  LayoutOutlined,
  PlusOutlined,
  UpSquareOutlined,
} from '@ant-design/icons';
import { useShortKey } from 'use-short-key';
import { useEffect, useMemo, useState } from 'react';
import createWorkerBox from 'workerboxjs';
import 'monaco-editor/esm/vs/basic-languages/typescript/typescript.contribution';
import 'monaco-editor/esm/vs/language/json/monaco.contribution';
import { editor } from 'monaco-editor/esm/vs/editor/editor.api';
// import 'monaco-editor/esm/vs/editor/editor.all.js';
import prettier from 'prettier/esm/standalone.mjs';
import typescript from 'prettier/esm/parser-typescript';
import stringify from 'safe-stable-stringify';
import JSONC from 'jsonc-simple-parser';
import { DataTable } from './DataTable';
import { DataText } from './DataText';
import { db } from './Data';
import { useLiveQuery } from 'dexie-react-hooks';
import _ from 'lodash';
import { CommandModal } from './CommandModal';

let theeditor: editor.IStandaloneCodeEditor;

const dataResults: { [key: string]: any } = {
  table: DataTable,
  text: DataText,
};

export default function Shell() {
  const [messageApi, contextHolder] = message.useMessage();
  const [result, setResult] = useState<any>();
  const [dataView, setDataView] = useState('text');
  const [isFull, setFull] = useState(false);
  const [type, setType] = useState('text');
  const commands = useLiveQuery(() =>
    db.commands.filter((it) => it.enabled).sortBy('index')
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const textResult = useMemo(() => {
    return !_.isObject(result) || _.isArrayLike(result)
      ? result?.toString()
      : stringify(
          result,
          (key, value) => {
            if (_.isDate(value)) {
              return value.toLocaleString();
            } else {
              return value;
            }
          },
          2
        ) || '';
  }, [result]);

  async function deleteCommand(id: any) {
    await db.commands.delete(id);
    messageApi.success('删除成功');
  }

  function handleLog(...args: any[]) {
    setDataView('text');
    if (args.length === 1) {
      setResult(args[0]);
    } else {
      setResult(args);
    }
    setOpen(true);
  }

  async function runScript() {
    trackClick('runScript', '执行脚本');
    const selection = theeditor.getSelection();
    const selectedTextLength =
      (selection
        ? theeditor.getModel()?.getValueLengthInRange(selection)
        : 0) || 0;
    const text =
      selectedTextLength > 0 && selection
        ? theeditor.getModel()?.getValueInRange(selection)
        : theeditor.getValue();
    const { run, destroy } = await createWorkerBox(null);
    const scope = {
      console: {
        log: handleLog,
        error: handleLog,
        warn: handleLog,
        info: handleLog,
        clear: () => {
          setDataView('text');
          setResult('');
          setOpen(true);
        },
        table: (data: any) => {
          setDataView('table');
          setResult(data);
          setOpen(true);
        },
      },
    };
    try {
      const ret = await run(text || '', scope);
      if (ret) {
        // messageApi.success(ret);
        handleLog(ret);
      }
    } catch (err) {
      messageApi.error('失败' + (err as any).message);
    } finally {
      destroy();
    }
  }

  async function formatScript() {
    trackClick('formatScript', '格式化脚本');
    try {
      theeditor.setValue(
        prettier.format(theeditor.getValue(), {
          parser: 'typescript',
          plugins: [typescript],
        })
      );
    } catch (err) {
      messageApi.error((err as any).message);
    }
  }

  async function formatJSON() {
    trackClick('formatJSON', '格式化JSON');
    try {
      theeditor.setValue(
        stringify(JSONC.parse(theeditor.getValue()), null, 2) || ''
      );
    } catch (err) {
      messageApi.error((err as any).message);
    }
  }

  async function clearScript() {
    trackClick('clearScript', '清理脚本');
    theeditor.setValue('');
  }

  async function undo() {
    trackClick('undo', '撤销');
    theeditor.trigger(null, 'undo', null);
  }
  async function redo() {
    trackClick('redo', '重做');
    theeditor.trigger(null, 'redo', null);
  }

  async function handleKeyDown(e: KeyboardEvent) {
    trackClick('keydown', '快捷键', {
      key: e.key,
      ctrlKey: e.ctrlKey,
      shiftKey: e.shiftKey,
    });
    e.preventDefault();
    if (e.key === 'F5' || e.key === 'F8') {
      runScript();
    }
    if (e.key === 'F3') {
      formatScript();
    }
    if (e.key === 'F4') {
      formatJSON();
    }
    if (e.key === 'F11') {
      clearScript();
    }
    if (e.key === 'F9') {
      insertScript();
    }
    if (e.key === 'F10') {
      setOpen(!open);
    }
    if (e.key === 'F12') {
      setFull(!isFull);
    }
    if ((e.key == 'z' || e.key == 'Z') && e.ctrlKey) {
      undo();
    }
    if (
      (e.key == 'y' || ((e.key == 'z' || e.key == 'Z') && e.shiftKey)) &&
      e.ctrlKey
    ) {
      redo();
    }
  }

  useShortKey({ code: 'F5', keydown: handleKeyDown, includeFormField: true });
  useShortKey({ code: 'F2', keydown: handleKeyDown, includeFormField: true });
  useShortKey({ code: 'F4', keydown: handleKeyDown, includeFormField: true });
  useShortKey({ code: 'F9', keydown: handleKeyDown, includeFormField: true });
  useShortKey({ code: 'F8', keydown: handleKeyDown, includeFormField: true });
  useShortKey({ code: 'F11', keydown: handleKeyDown, includeFormField: true });
  useShortKey({ code: 'F12', keydown: handleKeyDown, includeFormField: true });
  useShortKey({ code: 'F10', keydown: handleKeyDown, includeFormField: true });
  useShortKey({
    code: 'z',
    key: 'Control',
    keydown: handleKeyDown,
    includeFormField: true,
  });
  useShortKey({
    code: 'y',
    key: 'Control',
    keydown: handleKeyDown,
    includeFormField: true,
  });
  useShortKey({
    code: 'Z',
    key: 'Control',
    keydown: handleKeyDown,
    includeFormField: true,
  });
  useShortKey({
    code: 'Y',
    key: 'Control',
    keydown: handleKeyDown,
    includeFormField: true,
  });
  useShortKey({
    code: 'z',
    key: 'Command',
    keydown: handleKeyDown,
    includeFormField: true,
  });
  useShortKey({
    code: 'z',
    key: 'Command',
    shiftKey: true,
    keydown: handleKeyDown,
    includeFormField: true,
  });
  useShortKey({
    code: 'Z',
    key: 'Command',
    keydown: handleKeyDown,
    includeFormField: true,
  });
  useShortKey({
    code: 'Z',
    key: 'Command',
    shiftKey: true,
    keydown: handleKeyDown,
    includeFormField: true,
  });

  const [theme, setTheme] = useState(
    localStorage.getItem('wsh.theme') || 'dark'
  );
  const [code, setCode] = useState(
    localStorage.getItem('wsh.code') || 'console.log([1,2,3]);'
  );
  useEffect(() => localStorage.setItem('wsh.theme', theme), [theme]);
  useEffect(() => localStorage.setItem('wsh.code', code), [code]);
  const [open, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
  }

  function editorDidMount(editor: editor.IStandaloneCodeEditor) {
    theeditor = editor;
    theeditor.focus();
  }

  async function executeCommand(script: string) {
    trackClick('executeCommand', '格式化脚本');
    const selection = theeditor.getSelection();
    const selectedTextLength =
      (selection
        ? theeditor.getModel()?.getValueLengthInRange(selection)
        : 0) || 0;
    const text =
      selectedTextLength > 0 && selection
        ? theeditor.getModel()?.getValueInRange(selection)
        : theeditor.getValue();
    const { run, destroy } = await createWorkerBox(null);
    const scope = {
      text,
    };
    try {
      const ret = await run(script, scope);
      if (ret !== null && ret !== undefined) {
        if (selectedTextLength > 0 && selection) {
          // 如果编辑操作成功，则使用新文本替换选中区域
          theeditor.executeEdits('user', [
            {
              range: selection,
              text: ret.toString(),
            },
          ]);
        } else {
          theeditor.setValue(ret.toString());
        }
      } else {
        messageApi.info('执行成功,没有返回值');
      }
    } catch (err) {
      messageApi.error('失败' + (err as any).message);
    } finally {
      destroy();
    }
  }

  function insertScript() {
    theeditor.getModel()?.applyEdits([
      {
        range: new monaco.Range(
          theeditor.getModel()!.getLineCount() + 1,
          0,
          theeditor.getModel()!.getLineCount() + 1,
          0
        ),
        text: textResult,
      },
    ]);
  }

  const ResultView = dataResults[dataView] as any;

  return (
    <div className={'wsh ' + theme}>
      <div className={'wsh-toolbar'}>
        <div className="left">
          <Button
            type="primary"
            className="run"
            icon={<CaretRightOutlined />}
            title="F5执行脚本"
            onClick={runScript}
          ></Button>
          <Divider type="vertical"></Divider>
          <Button type="primary" title="F2" onClick={formatScript}>
            F3格式化脚本
          </Button>
          <Button type="primary" title="F4" onClick={formatJSON}>
            F4格式化JSON
          </Button>
          {commands?.map((item) => (
            <div key={item.id?.toString()} className="removebtn">
              <Button
                type="primary"
                title={item.description || item.name}
                onClick={() => executeCommand(item.script)}
              >
                {item.name}
              </Button>
              <div
                className="delete-btn"
                title="删除命令"
                onClick={() => deleteCommand(item.id)}
              >
                x
              </div>
            </div>
          ))}
          <Button
            className="add"
            type="primary"
            icon={<PlusOutlined />}
            title="添加命令"
            onClick={() => setIsModalOpen(true)}
          ></Button>
        </div>
        <div className="center">
          <Space></Space>
        </div>
        <Space className="right">
          <Button
            type="primary"
            title="F9清空脚本"
            icon={<ClearOutlined />}
            onClick={clearScript}
          ></Button>
          <Button
            type="primary"
            icon={open ? <UpSquareOutlined /> : <DownSquareOutlined />}
            title="显示结果"
            onClick={() => setOpen(true)}
          ></Button>
          <Switch
            className="theme"
            checkedChildren="深"
            unCheckedChildren="浅"
            checked={theme === 'dark'}
            onChange={(checked) => setTheme(checked ? 'dark' : 'light')}
          />
        </Space>
      </div>
      <MonacoEditor
        theme={theme === 'dark' ? 'vs-dark' : 'default'}
        value={code}
        onChange={setCode}
        language="typescript"
        options={{
          automaticLayout: true,
          scrollbar: {
            // vertical: 'hidden',
          },
          minimap: {
            // autohide: true,
            enabled: false,
          },
        }}
        editorDidMount={editorDidMount}
      ></MonacoEditor>
      <Drawer
        title="结果"
        placement={'bottom'}
        closable={false}
        onClose={onClose}
        open={open}
        height={isFull ? '100%' : ''}
        extra={
          <Space>
            <Button
              type="text"
              title="F9插入"
              icon={<ImportOutlined />}
              onClick={() => insertScript()}
            ></Button>
            <Button
              type="text"
              title={type === 'html' ? '切换成文本' : '切换成HTML'}
              icon={type === 'html' ? <EditOutlined /> : <LayoutOutlined />}
              onClick={() => setType(type === 'html' ? 'text' : 'html')}
            ></Button>
            <Button
              type="text"
              title="F12放大"
              icon={
                isFull ? <FullscreenExitOutlined /> : <FullscreenOutlined />
              }
              onClick={() => setFull(!isFull)}
            ></Button>
            <Button
              type="text"
              icon={<ClearOutlined />}
              onClick={() => {
                setResult([]);
                setOpen(false);
              }}
              title="F11清空数据结果"
            ></Button>
          </Space>
        }
        className="wsh-result"
      >
        <ResultView
          data={dataView !== 'text' ? result : textResult}
          isFull={isFull}
          type={type}
        ></ResultView>
      </Drawer>
      <CommandModal
        open={isModalOpen}
        defaultText={
          theeditor?.getSelection()
            ? theeditor.getModel()?.getValueInRange(theeditor.getSelection()!)
            : theeditor?.getValue()
        }
        onClose={() => setIsModalOpen(false)}
      ></CommandModal>
      {contextHolder}
    </div>
  );
}
