import Dexie, { IndexableType, Table } from 'dexie';
import shortid from 'shortid';

export interface Command {
  id?: IndexableType;
  name: string;
  description?: string;
  script: string;
  type: 'format';
  index?: number;
  enabled: boolean;
}

export interface Option {
  id?: IndexableType;
}

export class ShellDexie extends Dexie {
  // 'workspaces' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  commands!: Table<Command>;
  options!: Table<Option>;

  constructor() {
    super('jianguoke.webshell');
    this.version(3).stores({
      commands: '++id, name',
      options: '++id',
    });
  }

  async init() {
    if ((await this.options.count()) <= 0) {
      await this.options.add({});
    }
  }
}

export const db = new ShellDexie();
