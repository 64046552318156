import { Modal, Popover, Input } from 'antd';
import { useEffect, useState } from 'react';
import { db } from './Data';
import { QuestionCircleOutlined } from '@ant-design/icons';

export function CommandModal(props: {
  defaultText: string | undefined;
  open: boolean;
  onClose: () => void;
}) {
  const [name, setName] = useState('');
  const [text, setText] = useState(props.defaultText);

  useEffect(() => {
    setText(props.defaultText);
  }, [props.defaultText]);

  const handleOk = async () => {
    props.onClose();
    await db.commands.add({
      name,
      script: text!,
      type: 'format',
      enabled: true,
    });
    setName('');
    setText('');
  };

  const handleCancel = () => { 
    setText('');
    props.onClose();
  };

  return (
    <Modal
      title="添加命令"
      open={props.open}
      onOk={handleOk}
      onCancel={handleCancel}
      zIndex={10}
      okText="添加"
      cancelText="取消"
      okButtonProps={{
        disabled: !text || text.trim() === '' || name.trim() === '' || !name,
      }}
    >
      <p>
        <p>
          可以将当前代码作为格式化命令执行
          <Popover
            content={
              <div>
                <div>text: 当前文本内容,如果有选中即选中文本</div>
                <div>return: 返回格式化后的文本,如果有选择替换选中文本</div>
                <div>
                  例如: return JSON.stringify(JSON.parse(text), null, 2){' '}
                </div>
              </div>
            }
            title="格式化函数示例"
          >
            <QuestionCircleOutlined />
          </Popover>
        </p>
        <p>
          <Input.TextArea
            bordered={false}
            value={text}
            onChange={(e) => setText(e.target.value)}
          ></Input.TextArea>
        </p>
        <Input
          placeholder="命令名称"
          value={name}
          autoFocus
          onChange={(e) => setName(e.target.value)}
          onPressEnter={handleOk}
        />
      </p>
    </Modal>
  );
}
