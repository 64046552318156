import './DataText.less';
import parse from 'html-react-parser';
import _ from 'lodash';
import { Input } from 'antd';

export function DataText(props: { data: string; type: 'text' | 'html' }) {
  const render =
    props.type === 'html'
      ? parse
      : (value: any) => (
          <Input.TextArea
            value={value}
            readOnly
            bordered={false}
            autoSize
          ></Input.TextArea>
        );
  return <p className="wsh-result-text">{render(props.data)}</p>;
}
